
import { Component, Vue } from "vue-property-decorator";
import DropdownMenu from "@/components/common/DropdownMenu.vue";

@Component({
  components: {
    DropdownMenu,
  },
})
export default class NavBar extends Vue {
  languages = ["en-GB", "en-US", "pl", "ru", "uk", "da"];
  showNavbar = false;

  get isLoggedIn() {
    return this.$store.getters.isLoggedIn;
  }

  changeShowNavbar() {
    this.showNavbar = !this.showNavbar;
  }

  hideNavbar() {
    this.showNavbar = false;
  }

  signout() {
    this.$router.push("/signout");
  }

  changeLang(lang: string) {
    localStorage.setItem("locale", lang);
    window.location.reload();
  }
}
