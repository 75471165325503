
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import { Fragment } from "vue-fragment";
import Navbar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
import Newsletter from "@/components/common/Newsletter.vue";
import Sidebar from "@/components/layout/Sidebar.vue";

@Component({
  components: {
    Navbar,
    Footer,
    Fragment,
    Newsletter,
    Sidebar,
  },
})
export default class LandingPage extends Vue {}
